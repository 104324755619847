import React, {useState, useContext} from "react"
import { SiteMetadata } from "../../components"
import { WavesSVG } from '../../components/SVG/'
import { Layout } from "../../layouts/Layout"
import {Form, Col, Button, InputGroup} from "react-bootstrap"
import CartContext from "../../context/CartContext"
import reactElementToJSXString from 'react-element-to-jsx-string'
import ym from 'react-yandex-metrika'
 
import "../../styles/order-style.css"

export default () => {

    const {getProductList} = useContext(CartContext);
    const {clearCart} = useContext(CartContext);

    const[checkingForm, setCheckingForm] = useState(false)
    const[requestSended, setRequestSended] = useState(false)
    
    const radiosPayWay = [
        { name: 'Онлайн оплата'},
        { name: 'Расчетный счет (для юрлиц)'}

      ]
    const [radioPayWay, setRadioPayWay] = useState(radiosPayWay[0].name)
    const [inputFields, setInputFields] = useState({
        firstName : '',
        lastName: '',
        userEmail: '',
        userPhone: null,
        userCountry: 'Россия',
        userCity: '',
        userZip: null,
        userAdress: '',        
        userPayWay: radiosPayWay[0].name
    })
    
    const changePayWay = (e) => {
        inputFields.userPayWay = e.target.value
        setRadioPayWay(e.target.value)
        setInputFields(inputFields)
    }

    const inputChange = (e) => {
        setValidated(false)
        inputFields[e.target.id] = e.target.value
        setInputFields(inputFields)
    }

    const [validated, setValidated] = useState(false)
    const handleSubmit = (event) => {
        event.preventDefault()
        event.target.blur()        
        const form = event.currentTarget    
        if (form.checkValidity() === false) event.stopPropagation()   
        else {            
            let fullProdInfo = getProductList()
            if(fullProdInfo[0] !== null) {
                setCheckingForm(true)
                inputFields.userOrder = htmlLetter(fullProdInfo[0])
                inputFields.userSum = fullProdInfo[1]
                inputFields.checkSpam = "trust checker"
                postToFormspree(inputFields)                
            } else alert("Ваша корзина пуста. Наполните ее!")      
        }
        setValidated(true) 
    }

    const postToFormspree = (formData) => fetch(`/php/order-sender.php`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json'
            }    
    }).then(r => 
        r.json())
    .then(json => {
            if(json.type === 'message') {
                setRequestSended(true)
                clearCart()
                ym('reachGoal','order', {order_price: formData.userSum, currency: "RUB"})
            } else {
                alert("Что-то пошло не так. Попробуйте переотправить форму позже или связаться с нами другим способом.")            
                setCheckingForm(false)
            }
        })

    const htmlLetter = (productList) => {

        let cartToShow = <></>
    
        if(productList !== null){
            productList = productList.map((product, index) => {
                if(product !== null) {
    
                    let choosenVares = <></>
                    if(product.vares != null){                
                        choosenVares = <div className="small font-italic">({product.vares})</div>
                    }
    
                    let choosenFeature = <></>
                    if(product.feature != null){
                        choosenFeature = <div className="col-3 small d-flex flex-wrap align-content-center">
                            {product.feature}
                            {product.abbr}
                        </div>
                    }
    
                    return <tr key={`prod_${index}`} style="border-bottom: 1px dashed; height: 45px;">
                                <td className="row">
                                    <div className="col">
                                        <div>{product.name}</div>
                                        {choosenVares} 
                                    </div>                    
                                    {choosenFeature}
                                </td>
                                <td className="d-flex flex-wrap align-content-center">
                                    <div>{product.price}<span className="small">руб.</span></div>
                                </td>
                                <td className="d-flex flex-wrap align-content-center">{product.qty}</td>
                           </tr>                
                } else return <></>
            })
    
            cartToShow = <table style="border: 1px solid #000; border-collapse: collapse;">
                            <thead>
                                <tr style="font-weight: bold; border-bottom: 1px solid #000;">
                                    <td style="min-width: 200px;">Наименование</td>
                                    <td style="min-width: 100px;">Цена</td>
                                    <td style="min-width: 50px;">Кол-во</td>
                                </tr>
                            </thead>
                            <tbody>
                            {productList}
                            </tbody>
                        </table>
        }
    
        return reactElementToJSXString(cartToShow)
    }

    return (
        <Layout>
            <SiteMetadata 
                title="Оформление заказа" 
                canonical="/shop/order/"
                description="Оформление заказа на сайте Пчелиный Хуторок" 
            />
            <WavesSVG down />
            <section className="container">
                <div className="row">
                {!requestSended ? 
                    <Form className="col-md-8 offset-md-2" noValidate validated={validated} onSubmit={handleSubmit}>
                        <fieldset disabled={checkingForm}>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="lastName" onChange={inputChange}>
                                <Form.Label>Фамилия</Form.Label>
                                <Form.Control required type="text" placeholder="Иванов"/>
                                <Form.Control.Feedback>Звучит неплохо!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="firstName" onChange={inputChange}>
                                <Form.Label>Имя</Form.Label>
                                <Form.Control required type="text" placeholder="Иван"/>
                                <Form.Control.Feedback>Звучит неплохо!</Form.Control.Feedback>
                            </Form.Group>                        
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="userEmail" onChange={inputChange}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control required type="email" placeholder="email@example.com"/>
                                <Form.Control.Feedback type="invalid">Введите корректный email</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="userPhone" onChange={inputChange}>
                                <Form.Label>Телефон</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>+</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control required type="number" placeholder="7 123 4567890"/>
                                    <Form.Control.Feedback type="invalid">Введите корректный номер</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <hr className="mb-4"/>
                        <Form.Row>
                            <Form.Group as={Col} controlId="userCountry" onChange={inputChange}>
                                <Form.Label>Страна доставки</Form.Label>
                                <Form.Control as="select">
                                    <option>Россия</option>
                                    <option>Беларусь</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="userCity" onChange={inputChange}>
                                <Form.Label>Населенный пункт</Form.Label>
                                <Form.Control type="text" placeholder="Москва" required />
                                <Form.Control.Feedback type="invalid">Заполните поле</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="userZip" onChange={inputChange}>
                                <Form.Label>Индекс</Form.Label>
                                <Form.Control type="number" placeholder="123456" required />
                                <Form.Control.Feedback type="invalid">Введите индекс</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="userAdress" onChange={inputChange}>
                                <Form.Label>Адрес</Form.Label>
                                <Form.Control type="text" placeholder="ул. Ленина 3 кв. 33" required/>
                                <Form.Control.Feedback type="invalid">Заполните адрес</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <hr className="mb-4"/>
                        <Form.Row>
                            <Form.Group as={Col}> 
                                <Form.Label>Способ оплаты</Form.Label>
                                    {radiosPayWay.map((radio, idx) => (
                                    <Form.Check
                                        key={idx}
                                        type="radio"
                                        size="sm"
                                        label={radio.name}
                                        value={radio.name} 
                                        checked={radioPayWay === radio.name}
                                        id={`payway_${idx}`}
                                        onChange={changePayWay}
                                    />
                                    ))}
                            </Form.Group>                               
                        </Form.Row>
                        <hr className="mb-4"/> 
                        <div className="small mb-4">После оформления заказа с вами свяжется менеджер для уточнения деталей.</div>                       
                        <Button type="submit" className="btn btn-primary btn-lg btn-block rounded">{checkingForm ? 'Обработка…' : 'Оформить заказ'}</Button>
                        </fieldset>
                    </Form>
                : <Col className="col-md-8 offset-md-2">
                    <h3 className="text-center">Ваш заказ успешно оформлен!<br/>Мы свяжемся с Вами в ближайшее время.</h3>

                </Col>}
                </div>
            </section>
        </Layout>
    )}

